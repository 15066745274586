var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"data-list"}},[_c('vx-card',{ref:"filterCard",staticClass:"user-list-filters mb-8 vs-con-loading__container",attrs:{"title":"Send new News","id":"div-loading","collapse-action":"","refresh-content-action":""},on:{"refresh":_vm.resetColFilters}},[_c('validation-observer',{ref:"observer"},[_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-2"},_vm._l((_vm.company_categories),function(category,index){return _c('div',{key:index,staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-2/5 sm:w-2/5 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("COMPANY ")]),_c('validation-provider',{attrs:{"vid":'vp' + category.id,"name":"company_categories","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-4",attrs:{"label":"name","name":"company_categories","reduce":function (name) { return name.id; },"options":_vm.companies,"clearable":false,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"required":!!errors[0],"placeholder":"Company"},model:{value:(category.company_id),callback:function ($$v) {_vm.$set(category, "company_id", $$v)},expression:"category.company_id"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-2/5 sm:w-2/5 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("CATEGORY ")]),_c('validation-provider',{attrs:{"vid":'vp' + index,"name":'categories' + index,"rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-4 md:mb-0 vs_error",attrs:{"label":"name","reduce":function (name) { return name.id; },"multiple":"","name":'categories' + index,"options":_vm.categories,"clearable":false,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"required":!!errors[0],"placeholder":"Category"},model:{value:(category.categories),callback:function ($$v) {_vm.$set(category, "categories", $$v)},expression:"category.categories"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/5 sm:w-1/5 w-full m-auto",staticStyle:{"display":"flex","align-items":"flex-end","justify-content":"flex-end"}},[_c('vs-button',{staticClass:"mr-2",staticStyle:{"floate":"inline-start !important"},attrs:{"radius":"","color":"success","type":"border","icon":"add"},on:{"click":function($event){return _vm.addCompany()}}}),(index != 0)?_c('vs-button',{staticStyle:{"floate":"inline-start !important"},attrs:{"radius":"","color":"danger","type":"border","icon":"delete"},on:{"click":function($event){return _vm.removeCompany(index)}}}):_vm._e()],1)])}),0),_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-full sm:w-full w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("Subject ")]),_c('validation-provider',{attrs:{"name":"subject","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-chevron-right","icon-no-border":"","danger":!!errors[0],"danger-text":errors[0]},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})]}}])})],1),_c('div',{staticClass:"vx-col md:w-full sm:w-full w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("News ")]),_c('validation-provider',{attrs:{"name":"body","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"counter":errors[0],"counter-danger":!!errors[0],"height":"150px"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})]}}])})],1)])]),_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("Upload Image ")]),_c('vs-upload',{ref:"getFile",attrs:{"limit":"1"},on:{"change":_vm.getFile}})],1)]),_c('vs-button',{staticClass:"mb-2",attrs:{"type":"filled"},on:{"click":_vm.addNews}},[_vm._v("Send")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }